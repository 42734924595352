// src/axiosInstance.js
import axios from 'axios';

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: 'https://backend.charismainfotainment.com', // Replace with your base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to add the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
