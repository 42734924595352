import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CustomSnackbar = ({ open, onClose, message, imgSrc }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={1000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        position: 'fixed',
        top: '50px',
        right: '20px',
        width: '596.15px',
        height: '80px',
        borderRadius: '59.22px',
        // backgroundColor: '#F4F4F4',
        backgroundColor: '#2D263D',
        boxShadow: '0px 2.02px 2.02px 0px #FFFFFF14 inset',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        
          padding:"1rem",
          paddingLeft:"1rem",
          backgroundColor: '#2D263D',
          justifyContent: 'center', // Center horizontally
          color: '#F4F4F4', // Ensure consistent text color
        //   color: '#fff',
          borderRadius: '59.22px',
        }}
      >
        {/* Dynamically render the image before the message */}
        {imgSrc && (
          <Box
            component="img"
            src={imgSrc}
            alt="icon"
            sx={{
              width: '40px',
              height: '40px',
              marginRight: '1rem',
              marginLeft:"2rem"
            }}
          />
        )}

        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24px',
            color: '#F4F4F4',
          }}
        >
          {message}
        </Typography>
      </Box>
    </Snackbar>
  );
};

export default CustomSnackbar;


// import React from 'react';
// import Snackbar from '@mui/material/Snackbar';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';

// const CustomSnackbar = ({ open, onClose, message,imgSrc }) => {
//   return (
//     <Snackbar
//       open={open}
//       autoHideDuration={5000}
//       onClose={onClose}
//       anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Controls positioning
//     >
//       <Box
//         sx={{
//           width: '400px',
//           height: '80px',
//           display: 'flex',
//           alignItems: 'center',
//           padding: '10px',
//           backgroundColor: '#2D263D',
//           color: '#fff',
//           borderRadius: '59.22px',
//         }}
//       >
        
//         <Typography
//           sx={{
//             fontFamily: 'Inter',
//             fontSize: '27.64px',
//             fontWeight: 600,
//             lineHeight: '31.58px',
//             color:'#F4F4F4;'
         

//           }}
//         >
//           {message}
//         </Typography>
//       </Box>
//     </Snackbar>
//   );
// };

// export default CustomSnackbar;
