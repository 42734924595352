import axiosInstance from '../../../axiosInstance';
import { apiEndpoint } from "../../data/config";


  
  export const Feedback = async (feedbackText) => {
    const config = {
      method: "post",
      url: `${apiEndpoint}/api/app_send_feedback`,
      data: {
        feedback: feedbackText,  // Pass the feedback text here
      },
    
    };
    return axiosInstance(config);
  };

