import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { home_web } from "./homeState";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import five from "../images/five.png";

const Movie = () => {
  const [images, setImages] = useState([]);
  const [hoverStates, setHoverStates] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Add loading state
  const [promoImage, setPromoImage] = useState(null);
  const [error500, setError500] = useState(false); // Add error state for 500 status

  useEffect(() => {
    home_web()
      .then((res) => {
        const data = res.data;
        if (data && data.length > 0) {
          const movieSection = data.find(
            (section) => section.title === "Movies"
          );
          if (movieSection && movieSection.list) {
            setImages(movieSection.list);
          }
          const promoSection = data.find(
            (section) => section.template_type === "promo_template_1"
          );
          if (
            promoSection &&
            promoSection.list &&
            promoSection.list.length > 0
          ) {
            setPromoImage(promoSection.list[0]); // Assuming there's only one promo image
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response && err.response.status === 500) {
          setError500(true);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
  }, []);

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };

  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;

    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

  const handleHover = (index, isHovered) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [index]: isHovered,
    }));
  };

  if (error500) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height to center the image vertically
        }}
      >
        <img
          src={five}
          alt="Error 500"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>
    );
  }

  return (
    <div>
      <Grid
        sx={{
          position: "relative",
          paddingTop: "4rem",
          paddingBottom: "4rem",
          paddingLeft: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
        }}
      >
        <div
          style={{
            width: "279px",
            //  marginTop:'130px',

            marginTop: "800px",

            borderRadius: "500.667px",

            //   height: "680.82px",
            //  height: "400.45px",
            height: "650.45px",
            // top: "1239px",
            left: "0%",
            top: "0%",
            // top:'780px',
            // marginTop:"150px",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(150px)",
            //   zIndex:-5,
            position: "absolute",
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subTitle1">Movies</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Link to="/movies" style={{ textDecoration: "none" }}>
              <Typography variant="subTitle2">View All</Typography>
            </Link>
          </Grid>
        </Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
          }}
        />

        <Grid
          container
          rowSpacing={{ xs: 2, sm: 6, md: 5 }}
          columnSpacing={{ xs: 2, sm: 1, md: 1, lg: 1.5 }}
          columns={{ xs: 4, sm: 8, md: 14, lg: 14 }}
          pt={6}
        >
          {loading
            ? // Show Skeleton loader while loading
              Array.from(new Array(10)).map((_, index) => (
                <Grid item xs={12} sm={2} md={2} lg={2} key={index}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "200px",
                      position: "relative",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
              ))
            : images.map((image, index) => (
                <Grid item xs={2} sm={2} md={2} lg={2} key={index}>
                  <div
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      cursor: "pointer",
                      borderRadius: "8px",
                      width: "100%",
                      height: "0",
                      paddingBottom: "150.25%", // This maintains a 16:9 aspect ratio
                    }}
                    onMouseEnter={() => handleHover(index, true)}
                    onMouseLeave={() => handleHover(index, false)}
                    onClick={() =>
                      handleClick(image.content_id, image.content_type_id)
                    }
                  >
                    <img
                      src={image.thumbnail}
                      alt={`Thumbnail ${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        objectFit: "cover",
                        transition: "transform 0.3s ease-in-out",
                        transform: hoverStates[index]
                          ? "scale(1.05)"
                          : "scale(1)", // Slight zoom
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "100%", // Full height of the container
                        // background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%)", // Black gradient
                        background:
                          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 100%)", // Custom gradient

                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end", // Align content to the bottom
                        padding: "0.5rem",
                        opacity: hoverStates[index] ? 1 : 0,
                        transition:
                          "opacity 0.3s ease-in-out, background 0.3s ease-in-out",

                        pointerEvents: "none", // Prevents the overlay from blocking clicks
                        boxSizing: "border-box", // Ensure padding does not affect height calculation
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "8px", // Small screens
                            sm: "8px", // Medium screens
                            md: "8px", // Large screens
                            lg: "12px",
                            xl: "20px",
                          },
                          lineHeight: {
                            xs: "8px", // Small screens
                            sm: "16px", // Medium screens
                            md: "30px", // Large screens
                            lg: "28px",
                            xl: "30px",
                          },

                          color: "white",
                          padding: 0,
                          margin: 0,
                          fontFamily: "inter !important",
                        }}
                      >
                        {image.content_title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "8px", // Small screens
                            sm: "6px", // Medium screens
                            md: "6px", // Large screens
                            lg: "10px",
                            xl: "12px",
                          },
                          lineHeight: {
                            xs: "8px", // Small screens
                            sm: "8px", // Medium screens
                            md: "12px", // Large screens
                            lg: "12px",
                            xl: "20px",
                          },
                          padding: 0,
                          margin: "0",
                          fontFamily: "inter !important",
                          color: "white",
                          // textOverflow: "ellipsis", // Handle text overflow
                          // whiteSpace: "nowrap", // Prevent text wrapping
                          // overflow: "hidden", // Hide overflowed text
                          display: "-webkit-box", // Enable flexbox for truncation
                          WebkitLineClamp: 3, // Limit to 3 lines
                          WebkitBoxOrient: "vertical", // Set box orientation
                          overflow: "hidden",
                        }}
                      >
                        {image.content_description}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              ))}
        </Grid>
      </Grid>

      {promoImage && (
        <Grid
        sx={{
          display: {
            xs: "none",
            sm: "flex",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
          onClick={() =>
            handleClick(promoImage.content_id, promoImage.content_type_id)
          }
        >
          <img
            src={promoImage.promo_poster_web}
            alt="Promo Banner"
            style={{
              width: "100%",
              position: "relative",
              cursor: "pointer",
            }}
          />
        </Grid>
      )}

{promoImage && (
        <Grid
        sx={{
          display: {
            xs: "flex",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
          onClick={() =>
            handleClick(promoImage.content_id, promoImage.content_type_id)
          }
        >
          <img
            src={promoImage.promo_poster_web}
            alt="Promo Banner"
            style={{
              width: "100%",
              height:"100%",
              minHeight:"9rem",
              position: "relative",
              cursor: "pointer",
              objectFit:"fill"
            }}
          />
        </Grid>
      )}
    </div>
  );
};

export default Movie;
