// import axiosInstance from '../../axiosInstance';
import { apiEndpoint } from "../../components/data/config";
import axiosInstance from '../../axiosInstance';








 
export const live_tv = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_get_livetv`,
    headers: {
    

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};
