import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  TextField,
  InputAdornment,

  List,
  ListItem,
 
  Divider,
} from "@mui/material";
// import DialogImage from "../../pages/images/dialogboxlogo.png";
import DialogImage from "../pages/images/dialogboxlogo.png";
import googleicons from "../pages/images/buttongoogle.png";
import AppleIcons from "../pages/images/Button Apple.png";
import { useEffect } from "react";
import countries from "../components/data/countries.json";
import search from "../pages/images/country search.svg";

const AuthDialog = ({
  open = false, // default value if open is null or undefined
  handleCloseDialog,
  setMobileNumber,
  mobileNumber,
  code,
  handleCloseCode,
  verificationCode,
  otp,
  setOtp, // Added to manage otp state

  appleLogin,
  resendTimer,
  handleResendCode,
  sendOtp,
  verifySessionOtp,
  login,
  countryCode,
  setCountryCode,
}) => {
  const inputRefs = useRef([]);

  const [dialogOpen, setDialogOpen] = useState(false); // Correct boolean state for dialog control
  const [country, setCountry] = useState(false); // Default to '+91' for India
  const handleCountryClick = (country) => {
    setCountryCode(`+${country.phone}`); // Update country code with "+" prefix
    setDialogOpen(false); // Close the dialog
  };

  
  // Focus on the next input field if refs are populated
  const focusNext = (index) => {
    if (index < otp.length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Focus on the previous input field
  const focusPrevious = (index) => {
    if (index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  // Focus on the first input when the dialog opens
  useEffect(() => {
    if (open && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [open]);

  // Handle input change - replace existing value and move to next
  const handleInputChange = (index, e) => {
    const value = e.target.value;
    const newOtp = [...otp];

    if (value.length === 1) {
      newOtp[index] = value;
      setOtp(newOtp);
      focusNext(index);
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  // Filter the list based on search query
  const filteredCountries = countries.filter((country) =>
    country.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleKeyDown = (index, e) => {
    const currentOtp = [...otp];

    if (e.key === "Backspace") {
      currentOtp[index] = "";
      setOtp(currentOtp);
      focusPrevious(index);
    } else if (e.key === "ArrowLeft") {
      focusPrevious(index);
    } else if (e.key === "ArrowRight") {
      focusNext(index);
    } else if (/^[0-9]$/.test(e.key)) {
      currentOtp[index] = e.key;
      setOtp(currentOtp);
      focusNext(index);
      e.preventDefault();
    }
  };
  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };






  
  return (
    <React.Fragment>
      {/* First Dialog */}
      <Dialog
        fullScreen={false}
        open={open || false} // Ensure it defaults to false if undefined or null
        onClose={handleCloseDialog}
        sx={{
          "& .MuiDialog-paper": {
            // width: "457px",
            width: {
              xs: "90%",
              sm: "320px",
              md: "320px",
              lg: "360px",
              xl: "400px",
            },
            height: {
              xs: "60%",
              sm: "450px",
              md: "450px",
              lg: "490px",
              // xl: "600.72px",
              xl: "520px",
            },
            // height: "600.72px",
            borderRadius: "34.93px",
            borderWidth: "2.33px 0px 0px 0px",
            // borderStyle: "solid",
            background:
              "linear-gradient(181.35deg, rgba(140, 115, 209, 0.5) 1.15%, rgba(179, 177, 200, 0.2) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
            boxShadow: "0px 4.66px 4.66px 0px #00000040",
            position: "relative", // Allows `top` and `left` to take effect
            backdropFilter: "blur(40.749961853027344px)", // Applies a blur effect
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
            opacity: 1, // Adjust opacity to ensure visibility
            overflow: "hidden",
            padding: {
              xs: "16px",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "3rem",
            },
            top: {
              xs: "1%",
              sm: "1rem",
              md: "1rem",
              lg: "3rem",
              xl: "0",
            },
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            borderRadius: "34.93px",
            // width: "460px",
            width: {
              xs: "100%",
              sm: "320px",
              md: "320px",
              lg: "360px",
              xl: "400px",
            },
            // height: "590px",
            height: {
              xs: "100%",
              sm: "450px",
              md: "450px",
              lg: "490px",
              // xl: "590px",
              xl: "520px",
            },
            top: "-1px",
            left: "-1px",
            right: "-1px",
            padding: "3px",
            zIndex: "20",
            overflow: "hidden",
            background:
              "linear-gradient(142.14deg, #C892FF 2.97%, rgba(0, 0, 0, 0) 51.41%)",
            borderImageSlice: "1",
            mixBlendMode: "overlay",
            WebkitMask:
              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
            boxShadow: "0px 4.66px 4.66px 0px #00000040",
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 30,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center", // Center content horizontally
            // height: "100%",
            fontFamily: "Inter important",
            fontWeight: 700,
            gap: "1rem", // Add gap between elements for spacing
          }}
        >
          <img
            src={DialogImage}
            alt="dialog"
            style={
              {
                // width: "50%",
                // height: "auto",
              }
            }
          />
          <DialogTitle
            sx={{
              textAlign: "center", // Center text within the dialog title
              width: "100%", // Ensures full width for text alignment
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                // fontSize: "30px",
                fontWeight: 300,
                lineHeight: "33.82px",
                letterSpacing: "-0.07em",
                textAlign: "center",
                color: "#F4F4F4",
                padding: "0",
                fontSize: {
                  xs: "28px",
                  sm: "24px",
                  md: "24px",
                  lg: "30px",
                  xl: "30px",
                },
              }}
            >
              Welcome to Evangel
            </Typography>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div id="recaptcha-container"></div>{" "}
            {/* Add reCAPTCHA container here */}
            <Typography
              sx={{
                // marginBottom: "14px",
                fontFamily: "Inter",
                fontSize: "11.27px",
                fontWeight: "400",
                lineHeight: "16.91px",
                color: "#FFDBFF",
              }}
            >
              Enter your Mobile number
            </Typography>
            {/* <!-- Make sure you have this in your HTML or component --> */}
            <TextField
              placeholder="Mobile number"
              variant="outlined"
              fullWidth
              value={mobileNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "").slice(0, 10); // Ensure only digits and max 10
                setMobileNumber(value);
              }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 10,
              }}
              sx={{
                display: "flex",
                height: "40px",
                padding: "9.865px 11.274px",
                justifyContent: "center",
                alignItems: "center",
                gap: "7.046px",
                borderRadius: "26.036px",
                backgroundColor: "#FFF",
                "& .MuiOutlinedInput-root": {
                  padding: "0",
                  "& fieldset": {
                    borderColor: "transparent", // Remove initial border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent", // Remove border on focus
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      onClick={() => setDialogOpen(true)} // Open dialog on click
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "55px", // Fixed width
                        height: "26px", // Fixed height
                        borderRadius: "13px",
                        backgroundColor: "#EDEFF3",
                        gap: "1px",
                        padding: "8px", // Adjust padding for inner spacing
                        lineHeight: "15px",
                        fontWeight: 400,
                        fontSize: "12px",
                        cursor: "pointer",
                        color: "#6B6B6B",
                      }}
                    >
                      {countryCode || "+ Code"}
                      <Box
                        sx={{
                          fontSize: "12px",
                          marginLeft: "4px",
                          opacity: "0.6",
                          color: "#6B6B6B",
                        }}
                      >
                        ▼
                      </Box>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            {/* Dialog for Country Selection */}
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              maxWidth="xs"
              fullWidth
              sx={{
                "& .MuiDialog-paper": {
                  // width: "457px",
                  width: {
                    xs: "90%",
                    sm: "350px",
                    md: "350px",
                    lg: "360px",
                    xl: "400px",
                  },
                  height: {
                    xs: "60%",
                    sm: "480px",
                    md: "480px",
                    lg: "490px",
                    // xl: "600.72px",
                    xl: "520px",
                  },
                  // height: "600.72px",
                  borderRadius: "34.93px",
                  borderWidth: "2.33px 0px 0px 0px",
                  // borderStyle: "solid",
                  background:
                    "linear-gradient(181.35deg, rgba(140, 115, 209, 0.5) 1.15%, rgba(179, 177, 200, 0.2) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                  boxShadow: "0px 4.66px 4.66px 0px #00000040",
                  position: "relative", // Allows `top` and `left` to take effect
                  backdropFilter: "blur(40.749961853027344px)", // Applies a blur effect
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                  opacity: 1, // Adjust opacity to ensure visibility
                  overflow: "hidden",
                  padding: {
                    xs: "16px",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1rem",
                    xl: "2rem",
                  },
                  top: {
                    xs: "1%",
                    sm: "1rem",
                    md: "1rem",
                    lg: "3rem",
                    xl: "0",
                  },
                },
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  borderRadius: "34.93px",
                  // width: "460px",
                  width: {
                    xs: "100%",
                    sm: "350px",
                    md: "350px",
                    lg: "360px",
                    xl: "400px",
                  },
                  // height: "590px",
                  height: {
                    xs: "100%",
                    sm: "480px",
                    md: "480px",
                    lg: "490px",
                    // xl: "590px",
                    xl: "520px",
                  },
                  top: "-1px",
                  left: "-1px",
                  right: "-1px",
                  padding: "3px",
                  zIndex: "20",
                  overflow: "hidden",
                  background:
                    "linear-gradient(142.14deg, #C892FF 2.97%, rgba(0, 0, 0, 0) 51.41%)",
                  borderImageSlice: "1",
                  mixBlendMode: "overlay",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                  boxShadow: "0px 4.66px 4.66px 0px #00000040",
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  zIndex: 30,
                }}
              >
                <TextField
                  variant="outlined"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                  placeholder="Search Country"
                  sx={{
                    // width: "345px",
                    width: {
                      xs: "100%",
                      sm: "320px",
                      md: "317px",
                      lg: "326px",
                      xl: "345px",
                    },
                    height: "37px",
                    borderRadius: "12px",
                    color: "#FFFFFF26",
                    background: "#FFFFFF26",
                    backdropFilter: " blur(60px)",

                    boxShadow: " 0px 30px 30px 0px #00000026",

                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      border: "none",
                      background: "#FFFFFF26",
                      "& fieldset": {
                        borderWidth: "0 2px 2px 2px",
                        borderColor: "#7D5CBF",
                        borderRadius: "12px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "0 2px 2px 2px",
                        borderColor: "#7D5CBF",
                        borderRadius: "12px",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "0 2px 2px 2px",
                        borderColor: "#7D5CBF",
                        borderRadius: "12px",
                      },
                    },
                    "&:hover": {
                      // Styles when hovered
                      background: "#FFFFFF26",
                      // Retain background
                      borderWidth: "0 2px 2px 2px",
                      borderColor: "#7D5CBF",
                      borderRadius: "12px",
                    },
                    "&.MuiInputBase-input-MuiOutlinedInput-input": {
                      background: "#FFFFFF26",
                      // Retain background
                      borderWidth: "0 2px 2px 2px",
                      borderColor: "#7D5CBF",
                      borderRadius: "12px",
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      letterSpacing: "-0.4px",
                      color: "#FFB7FF", // Text color
                      "&::placeholder": {
                        color: "#FFB7FF", // Placeholder color
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "22px",
                        letterSpacing: "-0.40799999237060547px",
                        textAlign: "left",
                      },
                    },

                    
                  }}
                  InputProps={{
                    style: {
                      height: "100%",
                      color: "white",
                      border: "none",
                      // boxShadow: "none",
                      background: "#62587c",

                      backdropFilter: " blur(60px)",

                      boxShadow: " 0px 30px 30px 0px #00000026",
                      padding: "8px 12px",
                      fontFamily: "Inter",
                    },
                    // Use endAdornment to position icon at the right
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box>
                          <img
                            src={search} // Replace `search` with the actual path of your icon
                            alt="country search icon"
                            style={{
                              width: "17px",
                              height: "17px",
                              opacity: "1", // Adjust opacity if needed
                            }}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography
                  sx={{
                    // padding:0,
                    color: "#898989",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: " 16.41px",
                    textAlign: "left",
                  }}
                >
                  Alphabetical
                </Typography>
              </Box>

              <Box
                sx={{
                  position: "relative",
                  zIndex: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  maxHeight: "150vh",
                  overflowY: "auto",
                  "&:hover": { overflowY: "auto" },
                  "&::-webkit-scrollbar": { display: "none" },
                  scrollbarWidth: "none",
                }}
              >
                <List
                  sx={{
                    position: "relative",
                    width: "345px",
                    // height: "414px",
                    maxHeight: "100%", // Max height for responsive
                    // overflowY: "auto",
                    // marginBottom:"-17rem",
                    opacity: 1,
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  {countries
                    .filter((country) =>
                      country.label
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((country, index) => (
                      <React.Fragment key={country.code}>
                        <ListItem
                          button
                          onClick={() => handleCountryClick(country)}
                          sx={{
                            color: "#D9D9D9",
                            width: "345px",
                            height: "50px", // Adjusted height to fit image and text
                            fontWeight: 400,
                            fontSize: "18px",
                          
                            //         paddingLeft:0,
                            // paddingRight:0,
                            paddingRight: {
                              xs: "38px",
                              sm: "1rem",
                              md: "1rem",
                              lg: "1rem",
                              xl: "1rem",
                            },
                            paddingLeft: {
                              xs: "38px",
                              sm: "1rem",
                              md: "1rem",
                              lg: "1rem",
                              xl: "1rem",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              gap: "8px",
                            }}
                          >
                            {/* Flag Image */}
                            <img
                              loading="lazy"
                              width="30"
                              height="30"
                              src={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png`}
                              alt={`${country.label} flag`}
                              style={{
                                borderRadius: "50%",
                                objectFit: "cover",
                                border: "2px solid #ffffff",
                              }}
                            />
                            {/* Country Name */}
                            <Box
                              sx={{
                                flexGrow: 1,
                                color: "#D9D9D9",
                                fontSize: "18px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                lineHeight: "21.09px",
                              }}
                            >
                              {country.label}
                            </Box>
                            {/* Country Code */}
                            <Box
                              sx={{
                                color: "#D9D9D9",
                                fontSize: "18px",
                                fontFamily: "Inter",
                              }}
                            >
                              +{country.phone}
                            </Box>
                          </Box>
                        </ListItem>
                        {index < countries.length - 1 && (
                          <Divider sx={{ border: "1px solid #6A6A6A" }} />
                        )}
                      </React.Fragment>
                    ))}
                </List>
              </Box>
            </Dialog>
            <DialogActions
              sx={{
                width: "100%", // Ensures full width for button alignment
                flexDirection: "column", // Stack buttons vertically
                gap: "0.5rem", // Add space between buttons
                // marginTop: "2rem",
                marginTop: {
                  xs: "16px",
                  sm: "1rem",
                  md: "1rem",
                  lg: "2rem",
                  xl: "3rem",
                },
                padding: "0px",
                "& .MuiButton-root": {
                  margin: 0, // Removes margin from all buttons inside DialogActions
                },
              }}
            >
              <Button
                onClick={sendOtp}
                //  onClick={handlePhoneSignIn}
                sx={{
                  width: "100%",
                  height: "40px",
                  padding: "9.865px 11.274px",
                  gap: "10.77px",
                  borderRadius: "26.04px",
                  opacity: 1,
                  boxShadow: "0px 10.77px 32.31px 0px #1B0C57",
                  background:
                    "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
                  color: "#fff",
                  fontSize: "13.02px",
                  lineHeight: "15.75px",
                  border: "1.08px solid transparent",
                  textTransform: "inherit",
                }}
                // onClick={handleCodeOpen}
              >
                SignIn
              </Button>
              <Button
                // onClick={() => login(

                // )}
                onClick={() => {
                  login();
                }}
                // onClick={handleClose}
                sx={{
                  width: "100%",
                  height: "40px",
                  padding: "9.865px 11.274px",
                  gap: "10.77px",
                  borderRadius: "26.04px",
                  opacity: 1,
                  background: "#fff",
                  color: "black",
                  textTransform: "inherit",
                  fontSize: "13.02px",
                  lineHeight: "15.75px",
                  "&:hover": {
                    background: "#fff", // Maintain the same background color on hover
                    color: "black", // Maintain the same text color on hover
                    boxShadow: "none", // Remove any shadow changes on hover
                  },
                }}
              >
                <img src={googleicons} alt="googleicons" />
                Sign In With Google
              </Button>

              <Button
                onClick={() => appleLogin()}
                // onClick={handleClose}
                sx={{
                  width: "100%",
                  height: "40px",
                  padding: "9.865px 11.274px",
                  gap: "10.77px",
                  borderRadius: "26.04px",
                  opacity: 1,
                  background: "black",
                  color: "white",
                  textTransform: "inherit",
                  fontSize: "13.02px",
                  lineHeight: "15.75px",
                  "&:hover": {
                    background: "black", // Maintain the same background color on hover
                    color: "white", // Maintain the same text color on hover
                    boxShadow: "none", // Remove any shadow changes on hover
                  },
                }}
              >
                <img src={AppleIcons} alt="googleicons" />
                Sign In With Apple
              </Button>
            </DialogActions>
          </DialogContent>
        </Box>
      </Dialog>
      {/* Second Dialog for Code Verification */}
      <Dialog
        fullScreen={false}
        open={code} // Correctly using the `code` state to control visibility
        onClose={handleCloseCode} // Correct handler for closing the dialog
        sx={{
          "& .MuiDialog-paper": {
            // width: "457px",
            width: {
              xs: "90%",
              sm: "320px",
              md: "320px",
              lg: "360px",
              xl: "400px",
            },
            height: {
              xs: "60%",
              sm: "450px",
              md: "450px",
              lg: "490px",
              xl: "520px",
            },
            // height: "600.72px",
            borderRadius: "34.93px",
            borderWidth: "2.33px 0px 0px 0px",
            // borderStyle: "solid",
            background:
              "linear-gradient(181.35deg, rgba(140, 115, 209, 0.5) 1.15%, rgba(179, 177, 200, 0.2) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
            boxShadow: "0px 4.66px 4.66px 0px #00000040",
            position: "relative", // Allows `top` and `left` to take effect
            backdropFilter: "blur(40.749961853027344px)", // Applies a blur effect
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
            opacity: 1, // Adjust opacity to ensure visibility
            overflow: "hidden",
            padding: {
              xs: "16px",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "3rem",
            },
            top: {
              xs: "1%",
              sm: "1rem",
              md: "1rem",
              lg: "3rem",
              xl: "0",
            },
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            borderRadius: "34.93px",
            // width: "460px",
            width: {
              xs: "100%",
              sm: "420px",
              md: "320px",
              lg: "360px",
              xl: "400px",
            },
            // height: "590px",
            height: {
              xs: "100%",
              sm: "450px",
              md: "450px",
              lg: "490px",
              xl: "520px",
            },
            top: "-1px",
            left: "-1px",
            right: "-1px",
            padding: "3px",
            zIndex: "20",
            overflow: "hidden",
            background:
              "linear-gradient(142.14deg, #C892FF 2.97%, rgba(0, 0, 0, 0) 51.41%)",
            borderImageSlice: "1",
            mixBlendMode: "overlay",
            WebkitMask:
              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
            boxShadow: "0px 4.66px 4.66px 0px #00000040",
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 30,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center", // Center content horizontally
            height: "100%",
            fontFamily: "Inter important",
            fontWeight: 700,
            gap: "1rem", // Add gap between elements for spacing
          }}
        >
          <img
            src={DialogImage} // Replace with actual image path
            alt="dialog"
            style={{
              width: "50%",
              height: "auto",
            }}
          />
          <DialogTitle
            sx={{
              textAlign: "center", // Center text within the dialog title
              width: "100%", // Ensures full width for text alignment
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",

                fontSize: {
                  xs: "29px",
                  sm: "29pxx",
                  md: "29px",
                  lg: "30px",
                  xl: "30px",
                },
                fontWeight: 300,
                lineHeight: "33.82px",
                letterSpacing: "-0.07em",
                textAlign: "center",
                color: "#F4F4F4",
              }}
            >
              Welcome to Evangel
            </Typography>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                marginBottom: "14px",
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "29px",
                color: "#FFF",
                textAlign: "center",
                padding: 0,
              }}
            >
              Enter Verification Code
            </Typography>

            {/* Verification Code Input Fields */}
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              {verificationCode.map((code, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)} // Attach inputRefs
                  value={otp[index]}
                  onChange={(e) => handleInputChange(index, e)} // Handle input change
                  onKeyDown={(e) => handleKeyDown(index, e)} // Handle backspace
                  type="text"
                  maxLength={1}
                  inputMode="numeric" // Forces a numeric keypad on mobile
                  style={{
                    width: "35px",
                    height: "42px",
                    // padding: "8px 0px",
                    textAlign: "center",
                    fontSize: "16px",
                    border: "none",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.47)",
                    // boxShadow: "0px 30px 30px 0px rgba(0, 0, 0, 0.15)",
                    outline: "none",
                    backgroundColor: "transparent",
                    color: "#FFF",
                    display: "flex",
                    alignItems: "center",
                    // backdropFilter: "blur(30px)",
                  }}
                />
              ))}
            </Box>

            {/* Countdown Timer */}
            <Typography
              onClick={resendTimer === 0 ? handleResendCode : null} // Allow click only when timer is 0
              sx={{
                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "14px",
                color:
                  resendTimer === 0
                    ? "rgba(253, 145, 255, 1)"
                    : "rgba(253, 145, 255, 1)", // Change color to pink when clickable
                cursor: resendTimer === 0 ? "pointer" : "default", // Change cursor when clickable
                "&:hover": {
                  textDecoration: resendTimer === 0 ? "none" : "none", // Add underline on hover if clickable
                },
              }}
            >
              {resendTimer > 0
                ? `Resend code in ${resendTimer} s`
                : "Resend OTP"}
            </Typography>

            <DialogActions
              sx={{
                width: "100%", // Ensures full width for button alignment
                flexDirection: "column", // Stack buttons vertically
                gap: "0.5rem", // Add space between buttons
                // marginTop: "2rem",
                marginTop: {
                  xs: "2rem",
                  sm: "1rem",
                  md: "0",
                  lg: "2rem",
                  xl: "0",
                },
                padding: "0px",
              }}
              onClick={verifySessionOtp}
            >
              <Button
                // // onClick={handleCodeOpen}
                onClick={verifySessionOtp}
                sx={{
                  width: "100%",
                  height: "40px",
                  padding: "9.865px 11.274px",
                  gap: "10.77px",
                  borderRadius: "26.04px",
                  opacity: 1,
                  boxShadow: "0px 10.77px 32.31px 0px #1B0C57",
                  background:
                    "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
                  color: "#fff",
                  fontSize: "13.02px",
                  lineHeight: "15.75px",
                  border: "1.08px solid transparent",
                  textTransform: "inherit",
                  backdropFilter: "blur(5.385599613189697px)",
                }}
              >
                Verify
              </Button>
            </DialogActions>
          </DialogContent>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default AuthDialog;
