import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles.css"; // Import your custom CSS file here
import five from "../images/five.png";
import four from "../images/400.png";
import RecentlyAdded from "./recentlyAdded";
import Continuewatching from "../homePage/Continuewatching";
import Movies from "./movie";
import Series from "./series";
import Songs from "./songs";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { home_web } from "./homeState";
import playButton from "../images/Vector (1).png";
import CryptoJS from "crypto-js";

function SampleNextArrow(props) {
  return <div style={{ display: "none" }} />;
}

function SamplePrevArrow(props) {
  return <div style={{ display: "none" }} />;
}

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500, // Increased speed for smoothness
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "ease-in-out", // Smooth easing
    fade: true, // Enables fade effect for smoother transitions
    pauseOnHover: false, // Disable pause on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
        
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
        
      },
    ],
  };

  const [bannerData, setBannerData] = useState([]);
  const [error500, setError500] = useState(false); // State for handling 500 errors
  const [error404, setError404] = useState(false); // Add error state for 500 status
  const navigate = useNavigate();
  const [continueWatchingData, setContinueWatchingData] = useState([]);

  useEffect(() => {
    home_web()
      .then((res) => {
        const data = res.data;
        if (data && data.length > 0) {
          const bannerSection = data.find(
            (section) => section.template_type === "BANNER"
          );
          const continueWatchingSection = data.find(
            (section) => section.template_type === "CONTINUEWATCHING"
          );
          if (bannerSection && bannerSection.list) {
            setBannerData(bannerSection.list);
          }
          if (continueWatchingSection && continueWatchingSection.list) {
            setContinueWatchingData(continueWatchingSection.list);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);

        // Check if it's a 500 status error and set the state
        if (err.response) {
          if (err.response.status === 500) {
            setError500(true); // Handle 500 error
          } else if (err.response.status === 404) {
            setError404(true); // Handle 404 error
          } else if (err.response.status === 401) {
            localStorage.removeItem("auth_token");
            navigate("/");

            // Optionally reload the page to ensure UI state updates
            window.location.reload();
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };

  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;

    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

  return (
    <>
      <div
        style={{
          maxWidth: "100vw",
          minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
          backgroundColor: "#1b0a28",
          position: "relative",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        {/* Background image for 500 error */}
        {error500 && (
          <div
            style={{
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh", // Full viewport height to center the image vertically
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: 10, // Ensure it appears above the normal content
              display: "flex",
            }}
          >
            <img
              src={five}
              alt="Error 500"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}

        {/* Render 404 Error Image */}
        {error404 && (
          <div
            style={{
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh", // Full viewport height to center the image vertically
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: 10, // Ensure it appears above the normal content
              display: "flex",
            }}
          >
            <img
              src={four}
              alt="Error 404"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}
        {!error500 && !error404 && (
          <>
            <div style={{ position: "relative" }}>
              <Slider {...settings}>
                {bannerData.map((item, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    {/* <div style={overlayStyle}></div> Overlay div */}
                    <Grid
                      sx={{
                        width: "3%",
                        height: "560px",
                        borderRadius: "560px",
                        top: "-148",
                        left: "-91.86", // Position from the left edge of the image
                        // Center the overlay horizontally and vertically
                        background: "rgba(118, 64, 232, 0.5)", // Semi-transparent overlay color
                        position: "absolute", // Position absolutely within the parent container
                        filter: "blur(140px)",
                      }}
                    />
                    <div style={data}></div>

                    <div style={overlayStyles}></div>
                    {/* <div style={{position:"relative"}}> */}

                    {/* </div> */}
                    <Grid
                      sx={{
                        display: {
                          xs: "none",
                          sm: "flex",
                          md: "flex",
                          lg: "flex",
                          xl: "flex",
                        },
                      }}
                    >
                      <img
                        src={item.slider_image} // Assuming the image URL is in `slider_image`
                        alt={`Banner${index}`}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: {
                          xs: "flex",
                          sm: "none",
                          md: "none",
                          lg: "none",
                          xl: "none",
                        },
                        width: {
                          xs: "100%", // Full width on small screens
                        },
                        // height: {
                        //   xs: "auto",  // Adjust height based on the width
                        // },
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          cursor: "pointer",
                          borderRadius: "8px",
                          width: "100%",
                          height: "0",
                          paddingBottom: "150.25%", // This maintains a 16:9 aspect ratio
                        }}
                      >
                        <img
                          src={item.responsive_image}
                          alt={`Banner ${index}`}
                          loading={index === 0 ? "eager" : "lazy"} // Eager loading for the first image
                          style={{
                            position: "absolute", // Make the image fill the container
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Image covers the container
                          }}
                        />
                      </div>
                    </Grid>

                    {/* <p>ஜே</p> */}
                    <Grid container>
                      <Grid
                        sx={{
                          position: "absolute",
                          top: "28%",
                          width: "638px",
                          height: "309px",

                          marginLeft: "11rem",
                          display: {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "none",
                            xl: "flex",
                          },
                        }}
                      >
                        <img src={item.image_title} alt="img"></img>
                      </Grid>
                      <Grid
                        sx={{
                          position: "absolute",
                          top: "28%",
                          marginLeft: "5rem",
                          width: "638px",
                          height: "220px",

                          display: {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "flex",
                            xl: "none",
                          },
                        }}
                      >
                        <img src={item.image_title} alt="img"></img>
                      </Grid>

                      <Grid
                        sx={{
                          position: "absolute",
                          top: "28%",
                          marginLeft: "5rem",
                          //  height:'40%',
                          width: "638px",
                          height: "190px",

                          display: {
                            xs: "none",
                            sm: "none",
                            md: "flex",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <img src={item.image_title} alt="img"></img>
                      </Grid>
                      <Grid
                        sx={{
                          position: "absolute",
                          top: "30%",
                          marginLeft: "2rem",
                          //  height:'40%',
                          width: "638px",
                          height: "150px",

                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "none",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <img src={item.image_title} alt="img"></img>
                      </Grid>

                      <Grid
                        sx={{
                          position: "absolute",
                          top: "40%",
                          // marginLeft: "5rem",
                          height: "106px",
                          marginTop: "175px",
                          left: "-20px",

                          display: {
                            xs: "flex",
                            sm: "none",
                            md: "none",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <img src={item.responsive_title} alt="img"></img>
                      </Grid>
                      {/* <Grid classes="home_button_gradients"></Grid> */}
                      <Grid
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                            xl: "flex",
                          },
                       
                          // width: "300px",
                          width:{
                            md:"150px",
                            lg:"300px",
                            xl:"300px",
                            sm:"150px"
                          },
                          //   height: "680.82px",
                          height: "993.45px",
                          
                          border: "1px",
                          background:
                            "linear-gradient(170.07deg, rgba(188, 46, 255, 0.72) 9.43%, rgba(128, 76, 238, 0) 117.55%)",

                          position: "absolute",
                          zIndex: "9.999999",
                          right: "200px",
                          transform: "rotate(87.26deg)",
                          filter: "blur(80.56px)",
                          // marginTop: "-50rem",
                          marginTop:{
                            md:"-39rem",
                            lg:"-50rem",
                            xl:"-50rem",
                            sm:"-37rem"

                          },
                          gap: "0px",
                          opacity: "9px",
                        }}
                      />
                      <Grid
                        sx={{
                          position: "absolute",

                          transform: "translate(-50%, -50%)",
                          zIndex: 2,
                          top: { xs: "60%", sm: "75%", md: "75%", lg: "68%" },
                          left: { xs: "50%", sm: "75%", md: "75%", lg: "78%" },
                        }}
                      >
                        <Button
                          sx={{
                            background:
                              "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
                            color: "#fff",
                            border: "1.597px solid transparent",
                            borderRadius: "60px",
                            textTransform: "initial",
                            fontSize: {
                              xs: "16px",
                              sm: "25px",
                              md: "25px",
                              lg: "25px",
                            },
                            fontWeight: 700,
                            lineHeight: "38.73px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            fontFamily: "Inter !important",
                            boxShadow: "0px 20px 60px 0px rgba(27, 12, 87, 1)",
                            position: "absolute",
                            opacity: 9.85,
                            height: {
                              xs: "40px",
                              sm: "55px",
                              md: "55px",
                              lg: "55px",
                            },
                            backdropFilter: "blur(94px)",
                            padding: {
                              xs: "0px 25px",
                              sm: "0px 30px",
                              md: "0px 45px",
                              lg: "0px 45px",
                            },
                          }}
                          onClick={() =>
                            handleClick(item.content_id, item.content_type_id)
                          }
                        >
                          <img
                            src={playButton}
                            alt="playButton"
                            style={{ marginRight: "10px" }}
                          />
                          Watch
                        </Button>
                      </Grid>
                    </Grid>

                    {/* </div> */}
                  </div>
                ))}
              </Slider>
            </div>
            {continueWatchingData.length > 0 && <Continuewatching data={continueWatchingData} />}
            <RecentlyAdded />
            <Movies />
            <Series />
            <Songs />
          </>
        )}
      </div>
    </>
  );
};

export default Banner;

const data = {
  width:"100%",
  // maxWidth:'100vw',
  height:"239px",
  top:"-148",
  left:"-91.86", // Position from the left edge of the image
  // Center the overlay horizontally and vertically
  // background: 'rgba(118, 64, 232, 0.5)', // Semi-transparent overlay color
  background:"linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0.9%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 86.4%)",
  position: "absolute", // Position absolutely within the parent container
  filter: "blur(20px)",
};

const overlayStyles = {
  width: "100%",
  height: "50%",
  top: "50%",
  left: "-91.86",
  background:"linear-gradient(180.1deg, rgba(27, 10, 40, 0) 44.79%, rgba(27, 10, 40, 0.401553) 56.48%, rgba(27, 10, 40, 0.674889) 67.63%, #1B0A28 86.49%)",
  position: "absolute",
  filter: "blur(1px)",
};
