import axiosInstance from "../../../../axiosInstance";
import { apiEndpoint } from "../../../data/config";

export const get_wishlist = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_get_wishlist`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};

export const Delete_wishlist = async (wishlistId) => {
  const config = {
    method: "delete",
    url: `${apiEndpoint}/api/app_remove_wishlist/${wishlistId}`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};
export const Delete_wishlist_All = async () => {
  const config = {
    method: "DELETE",
    url: `${apiEndpoint}/api/app_remove_wishlist/ALL`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {}, // Include required data if necessary
  };
  return axiosInstance(config);
};

export const Delete_History_All = async () => {
  const config = {
    method: "DELETE",
    url: `${apiEndpoint}/api/app_delete_history/ALL`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {}, // Include required data if necessary
  };
  return axiosInstance(config);
};

export const get_historylist = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_get_history`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};

export const Delete_history = async (watchhistory_id) => {

  const config = {
    method: "delete",
    url: `${apiEndpoint}/api/app_delete_history/${watchhistory_id}`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};

export const view_profile = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_view_profile`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};

export const Delete_profile = async () => {
  const config = {
    method: "delete",
    url: `${apiEndpoint}/api/app_delete_user`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};

export const profile_update = async (formData) => {
  const config = {
    method: "post",
    url: `${apiEndpoint}/api/app_update_profile`, // Replace with your actual API endpoint
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,
    },
  };

  return axiosInstance(config);
};

export const savePlaybackHistory = async (data) => {
  const config = {
    method: "post",
    // url: "https://backend.charismainfotainment.com/api/app_add_history", // API endpoint
    url: `${apiEndpoint}/api/app_add_history`,
   
    data: {
      content_id: data.content_id,
      // season_id: data.season_id,
      episode_id: data.episode_id,
      total_duration: data.total_duration,
    },
    headers: {
      "Content-Type": "application/json",
      // Authorization header will be set by axiosInstance interceptor
    },
  };

  try {
    const response = await axiosInstance(config);
  
    return response.data;
  } catch (error) {

    // throw error; // Optionally rethrow the error to handle it further up the call stack
  }
};
