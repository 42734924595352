import React, { useState } from "react";

import {
  Divider,
  Grid,
  Typography,
  Box,
  TextField,
  Button,
} from "@mui/material";
import FeedBackLogo from "../../../pages/images/feedbackLogo.png";
import { Feedback } from "./footerState";
import FeedIcon from "../../../pages/images/feedBackicon.png";
import CustomSnackbar from "../../Snackbar/snackbar";
import five from "../../../pages/images/five.png";
const FeedBack = () => {
  const [text, setText] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarImage, setSnackbarImage] = React.useState(""); // Dynamic image URL

  // Maximum number of characters allowed
  const maxCharacters = 300;

  // Handler to update text state
  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleClickSnackbar = (message, imgSrc) => {
    setSnackbarMessage(message);
    setSnackbarImage(imgSrc); // Set the dynamic image
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const [error500, setError500] = useState(false); // Add error state for 500 status
  const handleSubmit = async () => {
    try {
      const response = await Feedback(text);

      if (response.status === 200) {
        handleClickSnackbar("Feedback Sent!", FeedIcon);
        setText(""); // Clear the text field after submission
      } else if (response.status === 503) {
        handleClickSnackbar("Feedback failed to send!", FeedIcon);
      }
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError500(true);
      }
    }
  };
  if (error500) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height to center the image vertically
        }}
      >
        <img
          src={five}
          alt="Error 500"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Grid
        sx={{
          position: "relative",
          paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },

          paddingLeft: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
        }}
      >
        {/* <Grid classes="content_gradient_sub"></Grid> */}
        {/* <Grid classes="recent_gradient"></Grid> */}
        <div
          style={{
            width: "549px",

            borderRadius: "600.667px",

            height: "600.45px",

            left: "0%",
            top: "0%",

            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(170px)",

            position: "absolute",
          }}
        />

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{ position: "relative" }}>
            <Typography variant="subTitle1">FeedBack</Typography>
          </Grid>
        </Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
            marginTop: "2rem",
          }}
        />

        {/* <Grid classes="home_gradient" /> */}
        {/* <Grid classes="home_gradients" /> */}
        <div
          style={{
            width: "241.6px",
            //   height: "680.82px",
            height: "750.425px",
            borderRadius: "893.425px",
            border: "1px solid #230E60",
            top: "40.6%",
            flexShrink: 0,
            right: "10%",
            background:
              "linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)",

            position: "absolute", // Position absolutely within the parent container

            //  marginTop:'10rem',
            transform: "rotate(87.26deg)",
            //  filter: 'blur(100.56px)',
            filter: "blur(95.56px)",

            gap: "0px",
            opacity: "0px",
          }}
        />
        {/* <Grid classes="recent_gradient_sub"></Grid> */}
        <div
          style={{
            width: "279px",
            //  marginTop:'130px',

            marginTop: "1080px",

            borderRadius: "500.667px",

            //   height: "680.82px",
            //  height: "400.45px",
            height: "650.45px",
            // top: "1239px",
            left: "0%",
            top: "0%",
            // top:'780px',
            // marginTop:"150px",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(190px)",
            //   zIndex:-5,
            position: "absolute",
          }}
        />

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          sx={{
            paddingBottom: { xs: "8rem", sm: "10rem", md: "7rem", lg: "5rem" },
            paddingTop: { xs: "8rem", sm: "10rem", md: "7rem", lg: "5rem" },
          }}
        >
          <Grid item xs={4} sm={1} md={2} lg={4} />

          <Grid item xs={4} sm={10} md={8} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "10vh",
                width: "100%",
              }}
            >
              <img
                src={FeedBackLogo}
                alt="Feedback Logo"
                style={{
                  maxWidth: "90%",
                  height: "auto",
                     position:"relative"
                }}
              />
            </Box>

            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: { xs: "24px", sm: "28px", md: "32px" }, // Responsive font size
                fontWeight: 500,
                lineHeight: "41.6px",
                textAlign: "center",
                color: "rgba(255, 255, 255, 1)",
                padding: "0.5rem",
                borderRadius: "8px",
                position:"relative"
              }}
            >
              evangel
            </Typography>

            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: { xs: "18px", sm: "20px", md: "22px" }, // Responsive font size
                fontWeight: 500,
                lineHeight: "130%",
                textAlign: "center",
                color: "#CD59E2",
                fontFeatureSettings: "'liga' off, 'clig' off",
                padding: "0.5rem",
                borderRadius: "8px",
                   position:"relative"
              }}
            >
              Gospel to all Nations
            </Typography>

            <Grid className="home_gradients" />
            <Grid className="recent_gradient_sub" />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#1b0a28",
                padding: "2rem",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "80%", md: "900px", lg: "993px" }, // Responsive width
                  textAlign: "left",
                  // marginBottom: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    fontFeatureSettings: "'liga' off, 'clig' off",
                    fontFamily: "Inter",
                    fontSize: { xs: "18px", sm: "20px", md: "22px" }, // Responsive font size
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "25px",
                    letterSpacing: "-0.4px",
                       position:"relative"
                  }}
                >
                  What could we do better?
                </Typography>

                <Typography
                  sx={{
                    color: "rgba(249, 249, 249, 0.50)",
                    fontFeatureSettings: "'liga' off, 'clig' off",
                    fontFamily: "Inter",
                    fontSize: { xs: "16px", sm: "18px", md: "19px" }, // Responsive font size
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "-0.4px",
                    // marginTop: "0.5rem",
                    marginBottom: "3rem",
                    padding: "0",
                       position:"relative"
                  }}
                >
                  We’ve been working hard on evangel, so your feedback is super
                  helpful to us.
                </Typography>
              </Box>

              <Box
                sx={{
                  position: "relative",
                  width: { xs: "100%", sm: "80%", md: "900px", lg: "993px" }, // Responsive width
                  height: "358px",
                  padding: "1.5px",
                  borderRadius: "12px",
                  backgroundColor: "rgba(255, 255, 255, 0.19)",
                  overflow: "hidden",
                  backgroundBlendMode: "overlay",
                  // zIndex: 30,
                }}
              >
                <Box
                  sx={{
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: "12px",
                    padding: "1.5px",
                    borderImageSlice: 2,
                    mixBlendMode: "overlay",
                    WebkitMask:
                      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                    maskComposite: "exclude",
                    background:
                      "linear-gradient(90deg, rgba(244, 244, 245, 0.4) 58.78%, rgba(142, 142, 143, 0.14) 100%)",
                  }}
                />

                <TextField
                  multiline
                  rows={14}
                  variant="outlined"
                  placeholder="How can we Improve?"
                  value={text}
                  onChange={handleTextChange}
                  inputProps={{
                    maxLength: maxCharacters,
                  }}
                  sx={{
                    width: { xs: "100%", sm: "80%", md: "900px", lg: "993px" }, // Responsive width
                    height: "358px",
                    borderRadius: "12px",
                    // border: "1px solid rgba(244, 244, 245, 0.40)",
                    // backgroundColor: "rgba(255, 255, 255, 0.19)",
                    // borderImageSource:
                    //   "linear-gradient(90deg, rgba(244, 244, 245, 0.4) 58.78%, rgba(142, 142, 143, 0.14) 100%)",

                    color: "#ffffff",
                    "& .MuiInputBase-root": {
                      position: "relative",
                    },
                    "& .MuiInputBase-input": {
                      color: "#ffffff",
                      caretColor: "#ffffff",
                      padding: "16px",
                      boxSizing: "border-box",
                      height: "100%",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "rgba(161, 161, 170, 1)",
                        fontFamily: "Inter",
                        fontSize: "17px",
                        fontWeight: 500,
                        lineHeight: "29px",
                        letterSpacing: "-0.4px",
                        textAlign: "left",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "30px",
                          right: "16px",
                          color: "#E650FF",
                          fontFamily: "Inter",
                          fontSize: "17px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "29px",
                          letterSpacing: "-0.4px",
                          fontFeatureSettings: "'liga' off, 'clig' off",
                          pointerEvents: "none",
                          padding: "0 4px",
                        }}
                      >
                        300
                      </Box>
                    ),
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Button variant="Submit_feedback" onClick={handleSubmit}>
                Send
              </Button>
            </Box>
          </Grid>

          <Grid item xs={4} sm={1} md={2} lg={4} />
        </Grid>

        {/* Centered Feedback Logo */}
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={5000}
        imgSrc={snackbarImage} // Dynamically pass the image URL
      />
    </div>
  );
};

export default FeedBack;
