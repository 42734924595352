import { Grid, Stack, Typography, Divider } from "@mui/material";
import React from "react";

import Google from "../../../pages/images/google.png";
import playstore from "../../../pages/images/playstore.png";
import YouTube from "../../../pages/images/utube.png";

import Instagram from "../../../pages/images/igram.png";
import Element from "../../../pages/images/fbook.png";
import Elemento1 from "../../../pages/images/twit.png";
import FooterLogo from "../../../pages/images/footerLogo.png";

import { Link } from "react-router-dom"; // Import Link from react-router-dom

const footer = () => {
  return (
    <>
      {/* <Grid classes="Footer"> */}
      <Grid sx={{
       
            overflow: "hidden",
            background: "#190C25",
           height: "318px",
          //  textAlign:'center',
          //  justifyContent:'center',
           display:'flex',
            // width:'1440px',
            // positiion:'fixed',
            bottom: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            borderRadius: "1px",
            position: "relative",
            paddingBottom: "2rem",
           paddingTop: "4rem",
            paddingLeft: "20rem",
            paddingRight: "20rem",
            "@media (max-width: 375px)": {
              height: "800px !important",
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft: "3rem",
              paddingRight: "3rem", // Override padding for screens up to 960px wide
            },
            "@media (max-width: 425px)": {
              height: "800px !important",
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft: "3rem",
              paddingRight: "3rem", // Override padding for screens up to 960px wide
            },
            "@media only screen and (min-width: 768px) and (max-width: 1000px)": {
              // height: "450px",
              height: "100%",
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft: "3rem",
              paddingRight: "3rem", // Override padding for screens up to 960px wide
            },
            "@media only screen and (min-width: 426px) and (max-width: 767px)" :{
              height: "100%",
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft:'2rem',
              paddingRight:'2rem', // Override padding for screens up to 960px wide
            },
           

           "@media only screen and (min-width: 1024px) and (max-width: 1043px)" :{

            paddingBottom: "3rem",
            paddingTop: "3rem",
            paddingLeft:'7rem',
            paddingRight:'7rem',

           },
         "@media only screen and (min-width: 1000px) and (max-width: 1285px)": {
          paddingBottom: "3rem",
          paddingTop: "3rem",
          paddingLeft:'13rem',
          paddingRight:'13rem',
       
          },
        //   "@media only screen and (min-width: 686px)": {
        //     paddingBottom: "3rem",
        //     paddingTop: "3rem",
        //     paddingLeft:'8rem',
        //     paddingRight:'8rem',
        //     },
             "@media screen and (min-device-width: 1044px) and (max-device-width: 1213px)": {
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft:'10rem',
              paddingRight:'10rem', // Override padding for screens up to 960px wide
            },
            "@media screen and (min-device-width: 1214px) and (max-device-width: 1221px)": {
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft:'13rem',
              paddingRight:'13rem', // Override padding for screens up to 960px wide
            },
            "@media screen and (min-device-width: 1222px) and (max-device-width: 1390px)": {
              paddingBottom: "3rem",
              paddingTop: "3rem",
              // paddingLeft:'15rem',
              // paddingRight:'15rem', // Override padding for screens up to 960px wide
               paddingLeft:'3rem',
              paddingRight:'3rem',

            },
        
      }}>
        {/* <Grid classes="footer_gradient_sub1" /> */}
        <div style={{
 width: "90%",
 height: "240px",
 flexShrink: 0,
 borderRadius: "1440px",
 background:'rgba(118, 64, 232, 0.46)',



 filter: "blur(60px)",
top:'0%',
left: "-5%",
 position: "absolute",
        }}/>
        {/* <Grid classes="footer_gradient_sub2" /> */}
        <div style={{
            width: "455px",
            height: "318.82px",
            flexShrink: 0,
            borderRadius: "455px",
            background: "rgba(88, 40, 167, 0.60)",
           filter: "blur(262px)",
            top: "266.97px",
            left: "-105px",
            position: "absolute",
        }}/>

        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 12, md: 12, lg: 12, xl: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid item xs={2} sm={0.1} md={0.5} lg={0.5} xl={2}></Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                // paddingLeft: "8px",
                paddingBottom: "2rem",
                position: "relative",
              }}
            >
              SOCIAL MEDIA
            </Typography>

            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              justifyContent="start"
              xs={12}
            >
              <Grid item>
                <img src={YouTube} alt="YouTube" />
              </Grid>
              <Grid item>
                <a
                  href="https://www.youtube.com/channel/UCChSZiMUVNALs0hoO0FfHTQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="h2">YouTube</Typography>
                </a>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              justifyContent="start"
              xs={12}
            >
              <Grid item>
                <img
                  src={Element}
                  alt="Instagram"
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
              <Grid item>
                <a
                  href="https://www.facebook.com/EvangelOtt"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="h2">FaceBook</Typography>
                </a>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              justifyContent="start"
              xs={12}
            >
              <Grid item>
                <img src={Instagram} alt="Instagram" />
              </Grid>
              <Grid item>
                <a
                  href="https://www.instagram.com/evangel_ott/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="h2">Instagram</Typography>
                </a>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              justifyContent="start"
              xs={12}
            >
              <Grid item>
                <a
                  href="https://x.com/evangelott?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img src={Elemento1} alt="Elemento1" />
                </a>
              </Grid>
              <Grid item>
                <Typography variant="h2">X</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3.5} xl={3}>
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",

                paddingBottom: "2.5rem",
              }}
            >
              HELP CENTER
            </Typography>
            <Grid container direction="column" spacing={2.5}>
              <Grid item>
                <Link to="/aboutus" style={{ textDecoration: "none" }}>
                  <Typography variant="h2">About Us</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/terms&condition" style={{ textDecoration: "none" }}>
                  <Typography variant="h2">Terms & Conditions</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/privacy&policy" style={{ textDecoration: "none" }}>
                  <Typography variant="h2">Privacy Policy</Typography>
                </Link>
              </Grid>

              <Grid item>
                {/* <Typography variant="h2" >Share Feedback</Typography> */}
                <Link to="/feedback" style={{ textDecoration: "none" }}>
                  <Typography variant="h2">Share Feedback</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} md={2} lg={4} xl={1}>
            <Grid item>
              <img src={FooterLogo} alt="frame" />
            </Grid>
            <Grid item>
              <Stack
                spacing={1}
                alignItems="start"
                container
                rows={{ xs: 12, sm: 12, md: 6, lg: 6 }}
                direction="row"
              >
              
                 <a
                  href="https://play.google.com/store/apps/details?id=com.ci.evangel&hl=en_IN"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                <img
                  src={Google}
                  alt="frame"
                  style={{
                    width: "133.37px",
                    borderRadius: "10.12px 0px 0px 0px",
                  }}
                />
                </a>
              
                <a
                  href="https://apps.apple.com/us/app/evangel-stream/id1602731863"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                <img
                  src={playstore}
                  alt="playstore"
                  style={{ width: "133.37px" }}
                />
                </a>
              
              
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={0.1} md={1} lg={0.5} xl={2}></Grid>
          <Grid
            item
            xs={12}
            sx={{
              // marginTop: "2rem",
              textAlign: "center",
            }}
          >
            <Divider
              sx={{
                width: "100%",
                height: "0px",
                border: "1px solid rgba(62, 69, 80, 1)",
              }}
            />
            <Typography variant="h3" sx={{ marginTop: "1rem" }}>
              © Copyright 2024, All Rights Reserved
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default footer;
