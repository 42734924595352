// import { createSlice } from '@reduxjs/toolkit';

// const videoSlice = createSlice({
//   name: 'video',
//   initialState: {
//     content_id: null,
//     content_type_id: null,
//     episode_id: null,
//     watchTime: 0, // Add watc
//   },
//   reducers: {
//     setVideoData: (state, action) => {
//       state.content_id = action.payload.content_id;
//       state.content_type_id = action.payload.content_type_id;
//       state.episode_id = action.payload.episode_id || null;
//     },
//     clearVideoData: (state) => {
//       state.content_id = null;
//       state.content_type_id = null;
//       state.episode_id = null;
//     },
//   },
// });

// export const { setVideoData, clearVideoData } = videoSlice.actions;
// export default videoSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const videoSlice = createSlice({
  name: 'video',
  initialState: {
    content_id: null,
    content_type_id: null,
    episode_id: null,
    watchTime: 0, // Add watchTime to track the time watched
  },
  reducers: {
    setVideoData: (state, action) => {
      state.content_id = action.payload.content_id;
      state.content_type_id = action.payload.content_type_id;
      state.episode_id = action.payload.episode_id || null;
    },
    clearVideoData: (state) => {
      state.content_id = null;
      state.content_type_id = null;
      state.episode_id = null;
      state.watchTime = 0; // Reset watchTime when video data is cleared
    },
    setWatchTime: (state, action) => {
      state.watchTime = action.payload; // Update the watchTime
    },
    incrementWatchTime: (state) => {
      state.watchTime += 1; // Increment watchTime by 1 second
    }
  },
});

export const { setVideoData, clearVideoData, setWatchTime, incrementWatchTime } = videoSlice.actions;
export default videoSlice.reducer;
